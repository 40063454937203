<template>
  <div class="wrapper">
    <!-- Header -->
    <div class="header">
        <div class="logo">
            <img src="@/assets/images/logo.png" alt="">
        </div>
        <div class="content">
            <div class="inner">
                <h1 class="code">404</h1>
                <p class="code">对不起，你要找的这个页面突然不见了。不过，放心，一切都在我的掌控之中，不会跑多远！</p>
            </div>
        </div>
        <div class="backerror" @click="handle">返回主页</div>
    </div>
    <!-- BG -->
    <div id="bg"></div>
</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Error",
  setup() {
      const router = useRouter();
     function handle(){
        router.push({name: "Index"})
     }
    return {
      handle
    };
  },
});
</script>


<style lang="scss" scoped>
.wrapper{
    width: 100%;
    height: 100%;
}

#wrapper:before {
    content: '';
    display: block;
}

.header{
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.logo{
    border: solid 1px #ffffff;
    border-radius: 100%;
    background: #ffffff;
    width: 1.867rem;
    height: 1.867rem;
    line-height: 1.867rem;
    border-radius: 50%;
    display: inline-block;
    img{
        width: 150%;
        height: 150%;
        position: relative;
        bottom: 25%;
        right: 25%;
    }
}

.content{
    width: 8.907rem;
    height: 3.893rem;
    border-style: solid;
    border-color: #ffffff;
    border-top-width: 1px;
    border-bottom-width: 1px;
    max-width: 100%;
    display: inline-block;
}

.content::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(70px - 1px);
    left: calc(50% - 1px);
    width: 1px;
    height: calc(40px + 1px);
    background: #ffffff;
}

.inner{
    padding: 30px 0;
    h1{
        font-size: 25px;
        color: #ffffff;
        margin: 0;
        padding: 0;
         margin-bottom: .4rem;
    }
    p{
        font-size: 12px;
        color: #ffffff;
        padding: 0 10px;
    }
}

.backerror{
    width: 144px;
    height: 44px;
        display: inline-block;
    font-size: 12px;
    background: #b9135a;
    color: #fff;
    line-height: 44px;
    border: 1px solid;
    margin-top: 40px;
    position: relative;
    border-radius: 4px;
}

.backerror::before{
    content: '';
    display: block;
    position: absolute;
    top: calc(-40px - 1px);
    left: calc(50% - 1px);
    width: 1px;
    height: calc(40px + 1px);
    background: #ffffff;
}

/* BG */

#bg {
    -moz-transform: scale(1.0);
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
    -webkit-backface-visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
}

#bg:before, #bg:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#bg:before {
    /* -moz-transition: background-color 2.5s ease-in-out; */
    /* -webkit-transition: background-color 2.5s ease-in-out; */
    /* -ms-transition: background-color 2.5s ease-in-out; */
    /* transition: background-color 2.5s ease-in-out; */
    /* -moz-transition-delay: 0.75s; */
    /* -webkit-transition-delay: 0.75s; */
    /* -ms-transition-delay: 0.75s; */
    /* transition-delay: 0.75s; */
    background-image: linear-gradient(to top, rgba(19, 21, 25, 0.5), rgba(19, 21, 25, 0.5));
    background-size: auto, 256px 256px;
    background-position: center, center;
    background-repeat: no-repeat, repeat;
    z-index: 2;
}

#bg:after {
    -moz-transform: scale(1.125);
    -webkit-transform: scale(1.125);
    -ms-transform: scale(1.125);
    transform: scale(1.125);
    /* -moz-transition: -moz-transform 0.325s ease-in-out, -moz-filter 0.325s ease-in-out; */
    /* -webkit-transition: -webkit-transform 0.325s ease-in-out, -webkit-filter 0.325s ease-in-out; */
    /* -ms-transition: -ms-transform 0.325s ease-in-out, -ms-filter 0.325s ease-in-out; */
    /* transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out; */
    // background-image: url("../assets/img/bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}

body.is-article-visible #bg:after {
    -moz-transform: scale(1.0825);
    -webkit-transform: scale(1.0825);
    -ms-transform: scale(1.0825);
    transform: scale(1.0825);
    -moz-filter: blur(0.2rem);
    -webkit-filter: blur(0.2rem);
    -ms-filter: blur(0.2rem);
    filter: blur(0.2rem);
}

body.is-loading #bg:before {
    background-color: #000000;
}


</style>
